<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">{{ title }}</h1>
    <v-toolbar flat color="toolbar-action transparent">
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="addItem">Assign Host</v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="namelists"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:item.start_date="{ item }">
        {{ formatDate(item.start_date) }}
      </template>
      <template v-slot:item.end_date="{ item }">
        {{ formatDate(item.end_date) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-dialog max-width="600" width="auto" :fullscreen="$vuetify.breakpoint.xsOnly" v-model="showManageDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Host Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showManageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <NamelistDetailForm
            :people_id="people_id"
            :subgroup_id="subgroup_id"
            :namelist_id="namelist_id"
            :isvisible="showManageDialog"
            @closed="onNamelistMangageDialogClosed"
          />
        </v-card>
      </template>
    </v-dialog>
    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
  </v-container>
</template>
<script>
import { dateMixin } from '@/mixins/dateMixin';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import NamelistDetailForm from '@/views/NamelistDetailForm';
import PersonService from '@/services/PersonService';
import SubgroupService from '@/services/SubgroupService';

export default {
  name: 'Namelist',
  components: {
    NamelistDetailForm,
    ConfirmDialog
  },
  mixins: [dateMixin],
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    const peopleID = parseInt(to.query.people_id) || null;
    const subgroupID = parseInt(to.query.subgroup_id) || null;
    if (peopleID > 0) {
      store.dispatch('namelist/list', { page: currentPage, people_id: peopleID, subgroup_id: subgroupID }).then(() => {
        to.params.page = currentPage;
        next();
      });
    } else {
      next();
    }
  },
  mounted: async function () {
    this.subgroup_id = parseInt(this.$route.query.subgroup_id, 10);
    this.people_id = parseInt(this.$route.query.people_id, 10);
    this.loadStudentDetails();
    this.loadSubgroupDetails();
  },
  methods: {
    addItem: function () {
      this.namelist_id = 'new';
      this.showManageDialog = true;
    },
    editItem(item) {
      this.namelist_id = item.id;
      this.showManageDialog = true;
    },
    deleteItem(item) {
      this.namelist_id = item.id;
      this.showDeleteConfirmDialog = true;
    },
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    async processDelete() {
      await store.dispatch('namelist/delete', this.namelist_id);
      this.load();
    },
    onNamelistMangageDialogClosed() {
      this.showManageDialog = false;
      this.load();
    },
    load() {
      store.dispatch('namelist/list', { page: 1, people_id: this.people_id, subgroup_id: this.subgroup_id });
    },
    /**
     * Load student details for the title
     */
    loadStudentDetails() {
      if (this.people_id > 0) {
        PersonService.get(this.people_id).then(response => {
          const data = response.data.data;
          if (data) {
            this.title = data.first_name;
          }
        });
      }
    },
    /**
     * Load specified subgroup information
     */
    loadSubgroupDetails() {
      if (this.subgroup_id > 0) {
        SubgroupService.get(this.subgroup_id).then(response => {
          const data = response.data.data;
          if (data) {
            this.group_id = data.group_id;
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters('namelist', ['loading', 'error', 'namelists', 'meta']),
    breadcrumbs: function () {
      return [
        {
          text: 'Dashboard',
          exact: true,
          to: '/dashboard'
        },
        {
          text: 'Groups',
          exact: true,
          to: '/groups'
        },
        {
          text: 'Group',
          disabled: false,
          exact: true,
          to: '/groups/' + this.group_id
        },
        {
          text: 'Subgroup',
          exact: true,
          to: '/subgroups/' + this.subgroup_id + '/namelist'
        }
      ];
    }
  },
  data: () => ({
    showManageDialog: false,
    showDeleteConfirmDialog: false,
    namelist_id: null,
    group_id: null,
    subgroup_id: null,
    people_id: null,
    title: ' ',
    headers: [
      { text: 'Host', value: 'host' },
      { text: 'Start date', value: 'start_date' },
      { text: 'End date', value: 'end_date' },
      { text: 'Comments', value: 'comments' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ]
  })
};
</script>
