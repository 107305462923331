<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid, dirty }">
      <v-form>
        <v-row>
          <v-col cols="12">
            <ValidationProvider name="Host" rules="required">
              <v-select
                v-model="namelistLocal.organisation_id"
                :disabled="loading"
                :items="lists.hosts"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Host"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Start Date" rules="required|date">
              <DateInput
                v-model="namelistLocal.start_date"
                label="Start Date"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="End Date" rules="date">
              <DateInput
                v-model="namelistLocal.end_date"
                label="End Date"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Comments">
              <v-textarea v-model="namelistLocal.comments" label="Comments" rows="3" filled auto-grow></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="saveAction" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
        <v-btn @click="deleteAction" :disabled="loading || namelist_id == 'new'" outlined color="primary" class="ma-2"
          >Delete</v-btn
        >
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, min, max } from 'vee-validate/dist/rules';
import { date } from '@/utils/helpers';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import DateInput from '@/views/DateInput';

extend('required', required);
extend('min', min);
extend('max', max);
extend('date', date);

export default {
  name: 'NamelistDetailForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    DateInput
  },
  props: ['people_id', 'subgroup_id', 'namelist_id', 'isvisible'],
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('namelist', ['loading', 'namelist', 'error', 'success', 'meta']),
    ...mapGetters('subgroup', ['subgroup']),
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        hosts: []
      };
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save.
     */
    namelistLocal: function () {
      const namelist = loadDash.cloneDeep(this.namelist);
      if (this.namelist_id == 'new' && this.subgroup) {
        namelist.start_date = this.subgroup.homestay_start_date;
        namelist.end_date = this.subgroup.homestay_end_date;
      }
      return namelist;
    }
  },
  methods: {
    /**
     * Load the namelist
     */
    load: function () {
      this.resetValidation();
      store.dispatch('namelist/get', {
        id: this.namelist_id,
        subgroup_id: this.subgroup_id,
        people_id: this.people_id
      });
    },
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result) {
        this.namelistLocal.subgroup_id = this.subgroup_id;
        this.namelistLocal.people_id = this.people_id;
        await store.dispatch('namelist/save', {
          id: this.namelistLocal.id,
          namelist: this.namelistLocal
        });
        this.$emit('closed');
      }
    },
    /**
     * Delete action for the form
     */
    deleteAction() {
      store.dispatch('namelist/delete', this.namelistLocal.id);
      this.$emit('closed');
    },
    /**
     * Reset the validation, this will make sure the dirty flags get set to
     * false after save action.
     */
    resetValidation() {
      requestAnimationFrame(() => {
        this.$refs.objects.reset();
      });
    }
  },
  mounted: async function () {
    this.load();
  },
  watch: {
    isvisible: function (newValue) {
      if (newValue) {
        this.load();
      }
    },
    success: function () {
      this.$emit('closed');
    }
  }
};
</script>
