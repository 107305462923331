import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Host","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.hosts,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","error-messages":errors,"label":"Host","clearable":""},model:{value:(_vm.namelistLocal.organisation_id),callback:function ($$v) {_vm.$set(_vm.namelistLocal, "organisation_id", $$v)},expression:"namelistLocal.organisation_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Start Date","rules":"required|date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"Start Date","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.namelistLocal.start_date),callback:function ($$v) {_vm.$set(_vm.namelistLocal, "start_date", $$v)},expression:"namelistLocal.start_date"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"End Date","rules":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"End Date","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.namelistLocal.end_date),callback:function ($$v) {_vm.$set(_vm.namelistLocal, "end_date", $$v)},expression:"namelistLocal.end_date"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Comments"}},[_c(VTextarea,{attrs:{"label":"Comments","rows":"3","filled":"","auto-grow":""},model:{value:(_vm.namelistLocal.comments),callback:function ($$v) {_vm.$set(_vm.namelistLocal, "comments", $$v)},expression:"namelistLocal.comments"}})],1)],1)],1),_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid || !dirty},on:{"click":_vm.saveAction}},[_vm._v("Save")]),_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading || _vm.namelist_id == 'new',"outlined":"","color":"primary"},on:{"click":_vm.deleteAction}},[_vm._v("Delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }